import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Row, Col, Toast } from 'react-bootstrap';

import { EntradaItensContainer } from './styles';

import ToggleDefault from '~/components/ToggleDefault';
import Separator from '~/components/Separator';
import { Cfop, Tributacao, Produto } from '~/pages/EntradaNFE/protocols';
import { tipoEmbalagens, unidades } from '~/pages/EntradaNFE/data';
import api from '~/services/api';

import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';

import Button from '@atlaskit/button';
import { buscaValorUnitario } from '~/pages/EntradaNFE/functions/Auxiliar';
import { ClearButton, ConfirmButton } from '~/components/Buttons';
import { defineCFOP } from '~/pages/EntradaNFE/functions/Auxiliares';
import {
  buscaInfoTributacaoEntrada,
  somaCampos,
} from '~/pages/EntradaNFE/functions/Procedures';
import {
  formatCurrencyAsText,
  moneyFormat,
  roundByRule,
  transformAsCurrency,
} from '~/utils/functions';
import { buscaTributacao } from '~/pages/EntradaNFE/functions/Auxiliares/BuscaTributacao';
import { HotKeys } from 'react-hotkeys';

import {
  InputText,
  InputNumber2,
  InputMoney,
  InputSelect,
  InputAsyncSelect,
} from '~/components/NovosInputs';

import { Util } from '~/pages/EntradaNFE/functions/Util';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import { insertOrUpdate } from '~/utils/masterDetail';
import { id } from 'date-fns/locale';
import { CircularProgress } from '@material-ui/core';
import { TiPencil, TiRefresh } from 'react-icons/ti';

export const FormItens: React.FC = () => {
  const {
    loja,
    fornecedor,
    perfil,
    produto,
    setProduto,
    setProdutos,
    produtos,
    formItem,
    canUpdate,
    uuidItens,
    setUuidItens,
    masterDetail,
    setMasterDetail,
    formNfe,
    disabledFormItens,
  } = useEntradaNFE();

  type Select = {
    value: string | number;
    label: string;
  };

  interface ItemTotais {
    qtd_embalagem: any;
    qtd_entrada: any;
    val_tabela: any;
    val_tot_desp_acess: any;
    val_tot_desconto: any;
    val_tot_acrescimo: any;
    val_tot_frete: any;
    val_tot_ipi: any;
    val_tot_st: any;
    flg_bonificado: any;
    des_unidade: any;
    des_referencia: any;
    cfop: any;
    cod_tributacao: any;
    val_tot_venda_varejo: any;
  }

  const [tributacoes, setTributacoes] = useState<Tributacao[]>([]);
  const [optsTributacoes, setOptsTributacoes] = useState<Select[]>([]);
  const [cfops, setCfops] = useState<Cfop[]>([]);
  const [optsCfops, setOptsCfops] = useState<Select[]>([]);
  const [tributacao, setTributacao] = useState<any>({} as Tributacao);
  const [tipoEmbalagemOld, setTipoEmbalagemOld] = useState<any>();

  const entradaItensContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState,
    setFocus,
    clearErrors,
    setError,
  } = formItem;
  const watchItem = formItem.watch;
  const { isDirty, errors } = formState;

  const { watch, getValues: getValuesFormNfe } = formNfe;

  useEffect(() => {
    reset({
      flg_bonificado: false,
    });
  }, []);

  const watchQtdEmbalagem: any = watchItem('qtd_embalagem');
  const watchFlgBonificado = watchItem('flg_bonificado');
  const watchCodTributacao = watchItem('cod_tributacao');
  const watchCfop = watchItem('cfop');

  const getTributacao = useCallback(
    async (item: any) => {
      if (item.cod_produto && loja && fornecedor) {
        const getTributacaoEntrada = async () => {
          const data = await buscaTributacao(
            loja,
            fornecedor,
            item,
            tributacoes,
          );
          return data;
        };
        if (uuidItens === undefined) {
          getTributacaoEntrada().then((data) => {
            let posTributacao = data;
            const { flg_optante_simples, cod_trib_simples } = fornecedor;
            if (
              flg_optante_simples &&
              [0, 2].includes(item.tipo_tributacao) &&
              cod_trib_simples > 0
            ) {
              posTributacao = cod_trib_simples;
            }

            const tribReg = tributacoes.find(
              (reg) => reg.cod_tributacao === posTributacao,
            );

            setTributacao(tribReg);

            const regTipoEmbalagem = tipoEmbalagens.find((tipoEmbalagem) => {
              return tipoEmbalagem.value === perfil.tipo_unidade;
            });

            setValue('tipo_embalagem', regTipoEmbalagem);

            setValue('cod_tributacao', {
              value: tribReg?.cod_tributacao,
              label: tribReg?.des_tributacao,
              ...tribReg,
            });

            setFocus('qtd_entrada');
          });
        }
      }
    },
    [fornecedor, loja, perfil, setFocus, setValue, tributacoes, uuidItens],
  );

  const watchTipoEmbalagem = watchItem('tipo_embalagem');

  useEffect(() => {
    if (watchTipoEmbalagem && uuidItens === undefined) {
      if (watchTipoEmbalagem.value === 1) {
        produto.des_unidade = produto.des_unidade_compra;
        produto.qtd_embalagem = produto.qtd_embalagem_compra;
      } else {
        produto.des_unidade = produto.des_unidade_venda;
        produto.qtd_embalagem = produto.qtd_embalagem_venda;
      }

      const regUnidade = unidades.find((unidade) => {
        return unidade.value === produto.des_unidade;
      });

      setValue('des_unidade', regUnidade);
      setValue('qtd_embalagem', produto.qtd_embalagem);
      if (tipoEmbalagemOld !== undefined) {
        setFocus('qtd_entrada');
      }
      setTipoEmbalagemOld(watchTipoEmbalagem);
    }
  }, [
    produto,
    setFocus,
    setValue,
    tipoEmbalagemOld,
    uuidItens,
    watchTipoEmbalagem,
  ]);

  useEffect(() => {
    if (tributacao?.cod_tributacao !== undefined) {
      const flgBonificado = getValues('flg_bonificado');
      const selectedCfop = defineCFOP(
        flgBonificado,
        tributacao,
        produto,
        loja,
        fornecedor,
        cfops,
      );

      if (
        selectedCfop !== undefined &&
        selectedCfop.cfop !== undefined &&
        selectedCfop !== null &&
        selectedCfop.cfop !== null &&
        uuidItens === undefined
      ) {
        setValue('cfop', {
          value: selectedCfop.cfop,
          label: `${selectedCfop.cfop} - ${selectedCfop.des_cfop}`,
        });

        setProduto((prevState) => {
          return {
            ...prevState,
            cfop: selectedCfop.cfop,
            flg_ignora_pis_cofins: selectedCfop.flg_ignora_pis_cofins,
          };
        });
      }
    }
  }, [tributacao]);

  useEffect(() => {
    getTributacoes();
  }, []);

  const watchPerfil = watch('cod_perfil');

  useEffect(() => {
    if (watchPerfil) {
      const codPerfil = watchPerfil.value;
      if (codPerfil) {
        getCfops(codPerfil);
      }
    }
  }, [watchPerfil]);

  async function getTributacoes() {
    try {
      const res = await api.get('/tributacao');
      const { success, data, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      const options = data.map((item: Tributacao) => {
        return {
          value: item.cod_tributacao,
          label: `${item.cod_tributacao} - ${item.des_tributacao}`,
          cod_tributacao: item.cod_tributacao,
          des_tributacao: item.des_tributacao,
          tipo_encerramento_diferido: item.tipo_encerramento_diferido,
          cod_situacao_tributaria: item.cod_situacao_tributaria,
          val_icms: item.val_icms,
          val_reducao: item.val_reducao,
          tipo_tributacao: item.tipo_tributacao,
        };
      });
      setTributacoes(options);
      setOptsTributacoes(options);
    } catch (e: any) {
      console.error(e);
    }
  }

  async function getCfops(codPerfil: string | number | undefined) {
    if (codPerfil) {
      try {
        const res = await api.get(`/perfil-cfop-cliC-fiscal/${codPerfil}`);
        const { success, data, message } = res.data;
        if (!success) {
          throw new Error(message);
        }
        const options = data.map((cfop: Cfop) => {
          return {
            value: cfop.cfop,
            label: `${cfop.cfop} - ${cfop.des_cfop}`,
            ...cfop,
          };
        });
        setCfops(data);
        setOptsCfops(options);
      } catch (e: any) {
        console.error(e);
      }
    }
  }

  useEffect(() => {
    if (watchCodTributacao && !watchCodTributacao.label) {
      const filtered = tributacoes.find(
        (reg) => reg.cod_tributacao === watchCodTributacao.value,
      );

      if (filtered) {
        setTributacao(filtered);

        setValue('cod_tributacao', {
          label: filtered.label,
          value: filtered.value,
        });
      }
    }
  }, [watchCodTributacao, uuidItens]);

  const handleChangeCFOP = useCallback(
    (cfop: any) => {
      if (cfop && produto) {
        setValue('cfop', cfop);
        // atualizar produto com id_ctb do cfop
        produto.cod_id_ctb = cfop.cod_id_ctb;
        produto.flg_ignora_pis_cofins =
          cfop.flg_ignora_pis_cofins || produto.flg_ignora_pis_cofins;
      }
    },
    [produto, setValue],
  );

  const getInfoNcm = useCallback(async () => {
    try {
      const res = await api.get('/entrada-nfe/info-ncm', {
        params: {
          cod_ncm: produto.cod_ncm,
          des_sigla: fornecedor.des_uf,
          cod_tipo_item: produto.cod_tipo_item,
        },
      });
      const { success, data, message } = res.data;
      if (!success || !data) {
        // throw new Error(message);
        return {
          per_pauta_iva_ncm: 0,
          per_fcp_st: 0,
          per_fcp_icms: 0,
        };
      }

      const { per_fcp_icms, per_fcp_st, per_pauta_iva_ncm } = data;

      return {
        per_pauta_iva_ncm,
        per_fcp_st,
        per_fcp_icms,
      };
    } catch (e: any) {
      console.error(e);
    }
  }, [fornecedor, produto]);

  const prepareItemForInsertion = useCallback(
    async (totais: ItemTotais, item?: Produto) => {
      const {
        qtd_embalagem,
        qtd_entrada,
        val_tabela,
        val_tot_desp_acess,
        val_tot_desconto,
        val_tot_acrescimo,
        val_tot_frete,
        val_tot_ipi,
        val_tot_st,
        flg_bonificado,
        des_unidade,
        des_referencia,
        cfop,
        cod_tributacao,
        val_tot_venda_varejo,
      } = totais;

      if (flg_bonificado && getValues('cfop').value.substring(1, 2) !== '9') {
        setError('cfop', {});
        return toast.warning(
          'Item marcado como Bonificado, Código Fiscal deve ser de bonificação.',
        );
      }

      let serializedProduto: any = item || produto;

      const uuid = uuidItens?.uuid || nanoid();
      const num_item =
        serializedProduto?.num_item ||
        Number(produtos[produtos.length - 1]?.num_item || 0) + 1;

      const qtd_total =
        (qtd_entrada * qtd_embalagem) / serializedProduto.val_peso;
      const tot_tabela = roundByRule(
        qtd_total * transformAsCurrency(val_tabela),
      );

      serializedProduto = {
        uuid,
        id: uuid,
        cod_seq_nf_item: uuidItens?.cod_seq_nf_item,
        num_item,
        ...serializedProduto,
        des_referencia,
        cfop: cfop.value,
        des_cfop: cfop.label,
        tipo_tributacao: cod_tributacao.tipo_tributacao,
        tipo_trib_entrada: cod_tributacao.tipo_tributacao,
        cod_tributacao: cod_tributacao.value,
        cod_trib_entrada: cod_tributacao.value,
        des_unidade: des_unidade.value,
        qtd_entrada: Number(qtd_entrada),
        qtd_embalagem: Number(qtd_embalagem),
        val_tabela: transformAsCurrency(val_tabela),
        val_tot_desp_acess: transformAsCurrency(val_tot_desp_acess || 0),
        val_tot_desconto: transformAsCurrency(val_tot_desconto || 0),
        val_tot_acrescimo: transformAsCurrency(val_tot_acrescimo || 0),
        val_tot_frete: transformAsCurrency(val_tot_frete || 0),
        val_tot_ipi: transformAsCurrency(val_tot_ipi || 0),
        val_tot_st: transformAsCurrency(val_tot_st || 0),
        val_tot_venda_varejo: transformAsCurrency(val_tot_venda_varejo || 0),
        flg_bonificado,
        val_bc_st_xml: 0,
        val_icms_st_xml: 0,
        val_bc_icms_xml: 0,
        val_icms_xml: 0,
        val_produto_xml: serializedProduto.val_produto_xml,
        val_despesa_acessoria_xml: 0,
        val_desconto_xml: 0,
        val_fcp_st_xml: 0,
        val_fcp_icms_xml: 0,
        val_pis_xml: 0,
        val_cofins_xml: 0,
        tot_tabela: transformAsCurrency(tot_tabela || 0),
      };

      const infoNcm = await getInfoNcm();

      if (!infoNcm) return;
      const { per_pauta_iva_ncm, per_fcp_st, per_fcp_icms } = infoNcm;
      serializedProduto.per_pauta_iva_ncm =
        transformAsCurrency(per_pauta_iva_ncm);
      serializedProduto.per_fcp_st = transformAsCurrency(per_fcp_st);
      serializedProduto.per_fcp_icms = transformAsCurrency(per_fcp_icms);

      serializedProduto.val_custo_rep_loja =
        item?.val_custo_rep || produto.val_custo_rep;
      serializedProduto.flg_nao_calc_bc = false;

      if (
        perfil.tipo_operacao === 1 &&
        perfil.tipo_emissao === 1 &&
        tributacao.tipo_tributacao === 3
      ) {
        serializedProduto.cod_sit_trib_entrada = '060';
      } else {
        serializedProduto.cod_sit_trib_entrada = tributacao.cod_sit_tributaria;
      }

      buscaInfoTributacaoEntrada(
        serializedProduto,
        loja,
        fornecedor,
        tributacoes,
        cod_tributacao.value,
        fornecedor.cod_trib_simples,
        cfop.value,
        fornecedor.flg_optante_simples,
      );

      const condisera_st = !fornecedor.flg_fabricante;

      await somaCampos(
        fornecedor,
        loja,
        serializedProduto,
        false,
        false,
        condisera_st,
        false,
      );

      const produtosData: any[] = await insertOrUpdate(
        'nf_itens',
        serializedProduto,
        masterDetail,
        setMasterDetail,
      );

      const produtosReordenados = Util.reordenarProdutos(produtosData);

      setProdutos(produtosReordenados);
      await FocusFilter('cod_produto');
    },
    [
      fornecedor,
      getInfoNcm,
      getValues,
      loja,
      masterDetail,
      perfil.tipo_emissao,
      perfil.tipo_operacao,
      produto,
      produtos,
      setError,
      setMasterDetail,
      setProdutos,
      tributacao,
      tributacoes,
      uuidItens?.cod_seq_nf_item,
      uuidItens?.uuid,
    ],
  );

  const ValRatItemBruto = (
    ValItem: number, // 12.5
    ValRat: number, // 1
    ValTotItem: number, // 50
  ): number => {
    return ValRat > 0 && ValTotItem > 0 ? ValItem * (ValRat / ValTotItem) : 0.0;
  };

  const isFractionalQuantity = () => {
    const { qtd_embalagem, qtd_entrada } = getValues();
    const { flg_ipv } = produto;

    const qtdTotal = qtd_embalagem * qtd_entrada;
    if (!flg_ipv && !Number.isInteger(Number(qtdTotal))) return true;
    return false;
  };

  const handleAddItem = handleSubmit(async () => {
    const {
      qtd_embalagem,
      qtd_entrada,
      val_tabela,
      val_tot_desp_acess,
      val_tot_desconto,
      val_tot_acrescimo,
      val_tot_frete,
      val_tot_ipi,
      val_tot_st,
      flg_bonificado,
      des_unidade,
      des_referencia,
      cfop,
      cod_tributacao,
      val_tot_venda_varejo,
    } = getValues();

    const {
      cod_produto,
      tipo_movimentacao,
      flg_explosao_compra,
      tipo_forma_explosao,
    } = produto;
    try {
      // Verifica se é explosão
      if (
        tipo_movimentacao === 1 &&
        flg_explosao_compra === true &&
        tipo_forma_explosao === 1
      ) {
        // Efetuar select dos itens filhos
        const res = await api.get('/entrada-nfe/busca-produtos-explosao', {
          params: {
            cod_loja: loja.cod_loja,
            cod_produto,
            cod_fornecedor: fornecedor.cod_fornecedor,
          },
        });

        if (res.data.success && res.data.data.length > 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            const produtoDecomposicao = res.data.data[i];
            const vValTotal: number = parseFloat(val_tabela) * qtd_entrada;

            const vQtdKit: number =
              qtd_entrada *
              qtd_embalagem *
              Math.trunc(produtoDecomposicao.qtd_decompoe);

            const vValTabela: number =
              (vValTotal * produtoDecomposicao.per_custo_kit) / 100 / vQtdKit;

            // vDespAcessUn
            const val_tot_desp_acess_ex = ValRatItemBruto(
              vValTabela * vQtdKit,
              parseFloat(val_tot_desp_acess),
              vValTotal,
            );

            // vIPIUn
            const val_ipi_ex = ValRatItemBruto(
              vValTabela * vQtdKit,
              parseFloat(val_tot_ipi),
              vValTotal,
            );
            // vSTUn
            const val_icms_st_ex = ValRatItemBruto(
              vValTabela * vQtdKit,
              parseFloat(val_tot_st),
              vValTotal,
            );
            // vDescontoUn
            const val_desconto_ex = ValRatItemBruto(
              vValTabela * vQtdKit,
              parseFloat(val_tot_desconto),
              vValTotal,
            );
            // acrescimo
            const val_acrescimo_ex = ValRatItemBruto(
              vValTabela * vQtdKit,
              parseFloat(val_tot_acrescimo),
              vValTotal,
            );
            // frete
            const val_frete_ex = ValRatItemBruto(
              vValTabela * vQtdKit,
              parseFloat(val_tot_frete),
              vValTotal,
            );
            // varejeo
            const val_varejo_ex = ValRatItemBruto(
              vValTabela * vQtdKit,
              parseFloat(val_tot_venda_varejo),
              vValTotal,
            );
            // vDescSuframaUn
            const vDescSuframaUn_ex = ValRatItemBruto(
              vValTabela * vQtdKit,
              0,
              vValTotal,
            );

            await prepareItemForInsertion(
              {
                qtd_embalagem,
                qtd_entrada: vQtdKit,
                val_tabela: vValTabela,
                val_tot_desp_acess: val_tot_desp_acess_ex,
                val_tot_desconto: val_desconto_ex,
                val_tot_acrescimo: val_acrescimo_ex,
                val_tot_frete: val_frete_ex,
                val_tot_ipi: val_ipi_ex,
                val_tot_st: val_icms_st_ex,
                flg_bonificado,
                des_unidade,
                des_referencia,
                cfop,
                cod_tributacao,
                val_tot_venda_varejo: val_varejo_ex,
              },
              produtoDecomposicao,
            );
          }
        }
        return;
      }

      await prepareItemForInsertion(
        {
          qtd_embalagem,
          qtd_entrada,
          val_tabela,
          val_tot_desp_acess,
          val_tot_desconto,
          val_tot_acrescimo,
          val_tot_frete,
          val_tot_ipi,
          val_tot_st,
          flg_bonificado,
          des_unidade,
          des_referencia,
          cfop,
          cod_tributacao,
          val_tot_venda_varejo,
        },
        produto,
      );
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      handleClearForm();
    }
  }, [formState]);

  const resetFormData = {
    qtd_embalagem: 0,
    qtd_entrada: 0,
    cod_produto: '',
    cfop: '',
    cod_tributacao: '',
    val_tabela: 0,
    des_referencia: '',
    flg_bonificado: false,
    tipo_embalagem: '',
    des_unidade: '',
    val_tot_desp_acess: '',
    val_tot_desconto: '',
    val_tot_acrescimo: '',
    val_tot_frete: '',
    val_tot_venda_varejo: '',
    val_tot_ipi: '',
    val_tot_st: '',
  };

  const handleClearForm = () => {
    setUuidItens(undefined);
    reset(resetFormData);
    setTributacao({} as Tributacao);
    setProduto({} as Produto);
    FocusFilter('cod_produto');
  };

  async function FocusFilter(idInput: string) {
    const input = await document.getElementById(idInput);
    input?.focus();
  }

  async function handleReferencia() {
    const cod_loja = await getValuesFormNfe('cod_loja')?.value;
    const cod_fornecedor = await getValuesFormNfe('cod_pessoa')?.cod_fornecedor;
    const cod_produto = await getValues('cod_produto')?.value;
    const des_referencia = await getValues('des_referencia');
    const qtd_embalagem_compra = await getValues('qtd_embalagem');
    const des_unidade_compra = await getValues('des_unidade').value;
    const res = await api.post('/entrada-nfe/referencia', {
      cod_loja,
      cod_fornecedor,
      cod_produto,
      des_referencia,
      qtd_embalagem_compra,
      des_unidade_compra,
    });
    if (res.data.success) {
      await toast.success(res.data.message);
    } else {
      await toast.warning(res.data.message);
    }
  }

  const handleSelectedProduct = useCallback(
    async (selected: any) => {
      const selectedAny: any = selected;
      setValue('des_referencia', selectedAny.des_referencia);
      setValue('cod_produto', selected);
      setValue('flg_bonificado', false);
      clearErrors('cod_produto');

      setProduto(selectedAny);
      setFocus('qtd_entrada');
      if (selected.val_custo_rep) {
        setValue('val_tabela', moneyFormat(selected.val_custo_rep, 4, 4));
      }
    },
    [clearErrors, setFocus, setProduto, setValue],
  );

  return (
    <>
      <Row>
        <Col md={9} sm={12}>
          <InputAsyncSelect
            label="Produto"
            id="cod_produto"
            maxLength={50}
            placeholder="Selecione..."
            title={
              disabledFormItens.isDisabled ? disabledFormItens.placeholder : ''
            }
            name="cod_produto"
            register={register}
            isError={!!formState.errors.cod_produto}
            control={control}
            disabled={!canUpdate || (canUpdate && disabledFormItens.isDisabled)}
            changeSelected={async (selected) => {
              const selectedAny: any = selected;
              await handleSelectedProduct(selectedAny);
              await getTributacao(selectedAny);
            }}
            api={{
              route: '/entrada-nfe/busca-produtos',
              method: 'get',
              bodyParams: {
                loja: getValuesFormNfe('cod_loja')?.value,
                cod_fornecedor: getValuesFormNfe('cod_pessoa')?.cod_fornecedor,
              },
              fields: ['cod_produto', 'des_produto'],
              dependsOf: ['loja'],
              messageForDependsOf:
                'É necessário selecionar a loja na capa da NF',
            }}
          />
        </Col>
        <Col md={3} sm={12}>
          <div className="input-container">
            <InputText
              label="Referência"
              maxLength={50}
              style={{ height: '42px' }}
              placeholder=""
              disabled={
                !canUpdate || (canUpdate && disabledFormItens.isDisabled)
              }
              title={
                disabledFormItens.isDisabled
                  ? disabledFormItens.placeholder
                  : ''
              }
              name="des_referencia"
              register={register}
              toLowerCase={false}
              isError={false}
              control={control}
            />
            <Button
              type="button"
              style={{ height: '42px' }}
              className="min-button"
              title="Vincular Referência ao Fornecedor"
              onClick={handleReferencia}
              isDisabled={
                getValues('des_referencia') === '' ||
                getValues('cod_produto')?.value === undefined ||
                !canUpdate ||
                (canUpdate && disabledFormItens.isDisabled)
              }
            >
              <TiPencil color="#fff" />
            </Button>
          </div>
        </Col>

        <Col md={6} sm={12}>
          <InputSelect
            label="Tributação"
            placeholder="Selecione..."
            title={
              disabledFormItens.isDisabled ? disabledFormItens.placeholder : ''
            }
            name="cod_tributacao"
            register={register}
            isError={!!formState.errors.cod_tributacao}
            control={control}
            options={optsTributacoes}
            changeSelected={(selected) => {
              clearErrors('cod_tributacao');

              setValue('cod_tributacao', selected);
            }}
            disabled={!canUpdate || (canUpdate && disabledFormItens.isDisabled)}
          />
        </Col>
        <Col md={5} sm={12}>
          <InputSelect
            label="CFOP"
            placeholder="Selecione..."
            title={
              disabledFormItens.isDisabled ? disabledFormItens.placeholder : ''
            }
            maxLength={50}
            name="cfop"
            register={register}
            isError={!!formState.errors.cfop}
            control={control}
            options={optsCfops}
            changeSelected={(selected: any) => {
              clearErrors('cfop');
              handleChangeCFOP(selected);
              if (selected.value.substring(1, 2) !== '9') {
                setValue('flg_bonificado', false);
              }
            }}
            disabled={!canUpdate || (canUpdate && disabledFormItens.isDisabled)}
          />
        </Col>
        <Col md={1} sm={4}>
          <ToggleDefault
            labelText="Bonificado?"
            setChecked={watchFlgBonificado}
            onSwitch={() => {
              setValue('flg_bonificado', !watchFlgBonificado);
            }}
            disabled={!canUpdate || (canUpdate && disabledFormItens.isDisabled)}
          />
        </Col>
      </Row>

      <Row className="d-flex align-items-center mt-2">
        <Col md={1} sm={4}>
          <p
            style={{
              marginTop: '16px',
              marginBottom: 0,
            }}
          >
            Entrada
          </p>
        </Col>
        <Col ref={entradaItensContainerRef} md={11} sm={12}>
          <EntradaItensContainer>
            <Row className="bg-green">
              <Col md={3} sm={4}>
                <InputSelect
                  label="Tipo da Embalagem"
                  placeholder="Selecione..."
                  title={
                    disabledFormItens.isDisabled
                      ? disabledFormItens.placeholder
                      : ''
                  }
                  name="tipo_embalagem"
                  register={register}
                  isError={false}
                  control={control}
                  options={tipoEmbalagens}
                  changeSelected={(selected) =>
                    setValue('tipo_embalagem', selected)
                  }
                  disabled={
                    !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                  }
                />
              </Col>
              <Col md={3} sm={4}>
                <InputSelect
                  label="Embalagem"
                  maxLength={50}
                  placeholder="Selecione..."
                  title={
                    disabledFormItens.isDisabled
                      ? disabledFormItens.placeholder
                      : ''
                  }
                  name="des_unidade"
                  register={register}
                  isError={false}
                  control={control}
                  options={unidades}
                  changeSelected={(selected) =>
                    setValue('des_unidade', selected)
                  }
                  disabled={
                    !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                  }
                />
              </Col>
              <Col md={2} sm={4}>
                <InputNumber2
                  control={control}
                  title={
                    disabledFormItens.isDisabled
                      ? disabledFormItens.placeholder
                      : ''
                  }
                  label="C/"
                  placeholder="0"
                  name="qtd_embalagem"
                  register={register}
                  maxLength={4}
                  min={1}
                  max={1000}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      const verifyQuantiy = isFractionalQuantity();
                      if (verifyQuantiy) {
                        return toast.warn(
                          'Quantidade Total não pode ser fracionada.',
                        );
                      }
                      handleAddItem();
                    }
                  }}
                  isError={!!errors.qtd_embalagem}
                  disabled={
                    !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                  }
                />
              </Col>
              <Col md={2} sm={4}>
                <InputNumber2
                  label="Quantidade"
                  title={
                    disabledFormItens.isDisabled
                      ? disabledFormItens.placeholder
                      : ''
                  }
                  placeholder="0"
                  name="qtd_entrada"
                  onChange={(e: any) => {
                    setValue('qtd_entrada', Number(e.target.value));
                  }}
                  maxLength={4}
                  min={1}
                  max={1000}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      const verifyQuantiy = isFractionalQuantity();
                      if (verifyQuantiy) {
                        return toast.warn(
                          'Quantidade Total não pode ser fracionada.',
                        );
                      }
                      handleAddItem();
                    }
                  }}
                  register={register}
                  isError={!!errors.qtd_entrada}
                  disabled={
                    !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                  }
                  control={control}
                />
              </Col>
              <Col md={2} sm={4}>
                <InputMoney
                  label="Valor Unitário"
                  title={
                    disabledFormItens.isDisabled
                      ? disabledFormItens.placeholder
                      : ''
                  }
                  placeholder="0,00"
                  name="val_tabela"
                  min={0}
                  decimals={4}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      const verifyQuantiy = isFractionalQuantity();
                      if (verifyQuantiy) {
                        return toast.warn(
                          'Quantidade Total não pode ser fracionada.',
                        );
                      }
                      handleAddItem();
                    }
                  }}
                  register={register}
                  isError={!!errors.val_tabela}
                  disabled={
                    !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                  }
                  control={control}
                />
              </Col>
            </Row>
          </EntradaItensContainer>
        </Col>
      </Row>

      <Row className="d-flex align-items-center mt-2">
        <Col md={1} sm={4}>
          <p
            style={{
              marginTop: '16px',
              marginBottom: 0,
            }}
          >
            Totais
          </p>
        </Col>
        <Col md={11} sm={12}>
          <EntradaItensContainer>
            <Row>
              <div className="itens-container">
                <div>
                  <InputMoney
                    label="Despesas Acessórias"
                    title={
                      disabledFormItens.isDisabled
                        ? disabledFormItens.placeholder
                        : ''
                    }
                    placeholder="0,00"
                    name="val_tot_desp_acess"
                    min={0}
                    decimals={2}
                    register={register}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        const verifyQuantiy = isFractionalQuantity();
                        if (verifyQuantiy) {
                          return toast.warn(
                            'Quantidade Total não pode ser fracionada.',
                          );
                        }
                        handleAddItem();
                      }
                    }}
                    isError={!!errors.val_tot_desp_acess}
                    disabled={
                      !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                    }
                    control={control}
                  />
                </div>
                <div>
                  <InputMoney
                    label="Desconto"
                    title={
                      disabledFormItens.isDisabled
                        ? disabledFormItens.placeholder
                        : ''
                    }
                    placeholder="0,00"
                    name="val_tot_desconto"
                    min={0}
                    decimals={2}
                    register={register}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        const verifyQuantiy = isFractionalQuantity();
                        if (verifyQuantiy) {
                          return toast.warn(
                            'Quantidade Total não pode ser fracionada.',
                          );
                        }
                        handleAddItem();
                      }
                    }}
                    isError={!!errors.val_tot_desconto}
                    disabled={
                      !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                    }
                    control={control}
                  />
                </div>
                <div>
                  <InputMoney
                    label="Acréscimo"
                    title={
                      disabledFormItens.isDisabled
                        ? disabledFormItens.placeholder
                        : ''
                    }
                    placeholder="0,00"
                    name="val_tot_acrescimo"
                    min={0}
                    decimals={2}
                    register={register}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        const verifyQuantiy = isFractionalQuantity();
                        if (verifyQuantiy) {
                          return toast.warn(
                            'Quantidade Total não pode ser fracionada.',
                          );
                        }
                        handleAddItem();
                      }
                    }}
                    isError={!!errors.val_tot_acrescimo}
                    disabled={
                      !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                    }
                    control={control}
                  />
                </div>
                <div>
                  <InputMoney
                    label="Frete"
                    placeholder="0,00"
                    title={
                      disabledFormItens.isDisabled
                        ? disabledFormItens.placeholder
                        : ''
                    }
                    name="val_tot_frete"
                    min={0}
                    decimals={2}
                    register={register}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        const verifyQuantiy = isFractionalQuantity();
                        if (verifyQuantiy) {
                          return toast.warn(
                            'Quantidade Total não pode ser fracionada.',
                          );
                        }
                        handleAddItem();
                      }
                    }}
                    isError={!!errors.val_tot_frete}
                    disabled={
                      !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                    }
                    control={control}
                  />
                </div>
                <div>
                  <InputMoney
                    label="Venda Varejo"
                    title={
                      disabledFormItens.isDisabled
                        ? disabledFormItens.placeholder
                        : ''
                    }
                    placeholder="0,00"
                    name="val_tot_venda_varejo"
                    min={0}
                    decimals={2}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        const verifyQuantiy = isFractionalQuantity();
                        if (verifyQuantiy) {
                          return toast.warn(
                            'Quantidade Total não pode ser fracionada.',
                          );
                        }
                        handleAddItem();
                      }
                    }}
                    register={register}
                    isError={!!errors.val_tot_venda_varejo}
                    disabled={
                      !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                    }
                    control={control}
                  />
                </div>
                <div>
                  <InputMoney
                    label="IPI"
                    placeholder="0,00"
                    name="val_tot_ipi"
                    title={
                      disabledFormItens.isDisabled
                        ? disabledFormItens.placeholder
                        : ''
                    }
                    min={0}
                    decimals={2}
                    register={register}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        const verifyQuantiy = isFractionalQuantity();
                        if (verifyQuantiy) {
                          return toast.warn(
                            'Quantidade Total não pode ser fracionada.',
                          );
                        }
                        handleAddItem();
                      }
                    }}
                    isError={!!errors.val_tot_ipi}
                    disabled={
                      !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                    }
                    control={control}
                  />
                </div>
                <div>
                  <InputMoney
                    label="ST"
                    placeholder="0,00"
                    name="val_tot_st"
                    title={
                      disabledFormItens.isDisabled
                        ? disabledFormItens.placeholder
                        : ''
                    }
                    min={0}
                    decimals={2}
                    register={register}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        const verifyQuantiy = isFractionalQuantity();
                        if (verifyQuantiy) {
                          return toast.warn(
                            'Quantidade Total não pode ser fracionada.',
                          );
                        }
                        handleAddItem();
                      }
                    }}
                    isError={!!errors.val_tot_st}
                    disabled={
                      !canUpdate || (canUpdate && disabledFormItens.isDisabled)
                    }
                    control={control}
                  />
                </div>
              </div>
            </Row>
          </EntradaItensContainer>
        </Col>
        <Col
          sm={12}
          className="mt-2 d-flex align-items-center justify-content-end align-self-end"
        >
          <ConfirmButton
            disabled={!canUpdate || (canUpdate && disabledFormItens.isDisabled)}
            title={
              disabledFormItens.isDisabled ? disabledFormItens.placeholder : ''
            }
            onClick={() => {
              const verifyQuantiy = isFractionalQuantity();
              if (verifyQuantiy) {
                toast.warn('Quantidade Total não pode ser fracionada.');
                return;
              }
              handleAddItem();
            }}
          >
            Confirmar
          </ConfirmButton>
          <ClearButton
            disabled={!canUpdate || (canUpdate && disabledFormItens.isDisabled)}
            title={
              disabledFormItens.isDisabled ? disabledFormItens.placeholder : ''
            }
            onClick={() => handleClearForm()}
          >
            Limpar
          </ClearButton>
        </Col>
      </Row>
    </>
  );
};
